import React from 'react'
import Layout from '@components/Layout'
import { Config, Features, Routes } from '@constants'
import FeatureListItem from '@components/features/FeatureListItem'
import FeatureHeader from '@components/features/FeatureHeader'
import BottomCta from '@components/BottomCta'
import HelmetHelper from '@components/HelmetHelper'
import FeatureRedirectList from '@components/features/FeatureRedirectList'
import PopularQuestionList, {
  createQuestionItem,
} from '@components/custom/PopularQuestionList'
import Testimonials from '@components/Testimonials'
import Typography from '@atoms/Typography'
import imgLimits from '@static/features/report-limits.svg'
import imgWarmup from '@static/features/account-warmup.svg'
import imgSafetyMain from '@static/features/safety-main.svg'
import videoProxy from '@static/features/proxy.mp4'
import videoActiveTimes from '@static/features/active.mp4'

const POPULAR_QUESTION_ITEMS = [
  createQuestionItem(
    'Why is Icereach LinkedIn automation safe to use?',
    <>
      <div className="mb-3">
        We combine several security measures to ensure your account security.
        Our algorithm uses random delays between LinkedIn actions to ensure
        everything looks natural. Each account has access to a unique IP address
        from your region. We use smart limits, automatic warm-up to comply with
        LinkedIn rules resulting in a 0% ban rate.
      </div>
    </>
  ),
  createQuestionItem(
    `What's the LinkedIn invitation limit per week?`,
    <>
      <div className="mb-3">
        You can send from 100 invitations on LinkedIn per week, with some
        accounts sending 200-300 invitations per week.
      </div>
      <div>
        However, we recommend gradually scaling up your daily limit by using our
        automated account warm-up and focusing on increasing your acceptance
        rate, as this is a significant factor in how many connection requests
        LinkedIn allows you to send.
      </div>
    </>
  ),
  createQuestionItem(
    `What is the safest LinkedIn automation tool?`,
    <>
      If you need an easy-to-use yet safe and powerful LinkedIn automation tool
      to outreach at scale, {Config.NAME} is for you. {Config.NAME} makes it
      seamless to run sales campaigns, personalize messages and automate
      follow-ups on LinkedIn while keeping you account safe.
    </>
  ),
  createQuestionItem(
    `How can I get more leads on LinkedIn without getting banned?`,
    <>
      <div className="mb-3">
        LinkedIn doesn&apos;t like suspicious activity and spamming people.
      </div>
      <div>
        To ensure your account is safe, you should use{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={Features.FOLLOWUP.link}
          className="text-link hover:underline"
        >
          personalized messages
        </a>
        , target the right audience, keep your acceptance rate high and use the
        safe LinkedIn automation tool like {Config.NAME}.
      </div>
    </>
  ),
  createQuestionItem(
    `How many connection requests can I send per day?`,
    `You can send about 20-30 connection requests per day on LinkedIn. Avoid getting suspended by gradually increasing the number of connections you send every day.`
  ),
  createQuestionItem(
    'Does my account connect from a unique IP address?',
    `Yes! You'll get a unique IP address from your region to ensure your account safety with each account.`
  ),
]

const SafetyPage = () => (
  <Layout>
    <HelmetHelper
      title={`100% Safe Automated LinkedIn Outreach - ${Config.NAME}`}
      description="0% ban rate. Avoid getting restricted by LinkedIn with our unique IP address from your country to comply with LinkedIn rules."
    />
    <FeatureHeader
      title="100% Safe Automated LinkedIn Outreach at Scale"
      subtitle={`Avoid getting restricted by LinkedIn with our unique IP address from your country and smart limits to comply with LinkedIn rules resulting in a 0% ban rate. Outreach at scale with ${Config.NAME} is entirely safe and secure.`}
      image={imgSafetyMain}
      clientText="Safe LinkedIn automation software"
    />

    <div className="pt-20">
      <div className="mx-auto w-full text-center max-w-full md:max-w-screen-lg">
        <Typography.H2>
          Automate your LinkedIn outreach with no risk
        </Typography.H2>
        <Typography.SUBTEXT>
          We combine several security measures to comply with LinkedIn rules
          resulting in no penalties for connected accounts.
        </Typography.SUBTEXT>
      </div>

      <FeatureListItem
        title="Access from your unique IP address"
        subtitle="You get a unique IP address from your local region assigned to your account to ensure all LinkedIn actions are made naturally without causing suspicion."
        // image={imgIPAddress}
        video={videoProxy}
        button="Get started"
        link={Routes.APP.USER.SIGN_UP}
      />

      <FeatureListItem
        title={`Avoid LinkedIn jail with ${Config.NAME}`}
        subtitle={
          <>
            <div className="mb-2">
              LinkedIn began experimenting with the weekly limit and most
              accounts can send from 100 invitations per week.
            </div>
            <div>
              Automatically randomize the number of invites and messages sent
              per day to comply with the LinkedIn limits.
            </div>
          </>
        }
        reverseOrder
        image={imgLimits}
        button="Get started"
        link={Routes.APP.USER.SIGN_UP}
      />

      <FeatureListItem
        title="Warm up your LinkedIn account gradually"
        subtitle="Just like athletes need to warm up before a game, your LinkedIn account needs to warm up before you start sending multiple invitations daily. Best part? It's 100% automatic."
        image={imgWarmup}
        button="Get started"
        link={Routes.APP.USER.SIGN_UP}
      />

      <FeatureListItem
        title="Your schedule with no restrictions"
        subtitle="Contact your prospects at the right time to increase the change of the reply without staying on LinkedIn during the night. Appear human to LinkedIn while you remain in control of scheduling."
        reverseOrder
        video={videoActiveTimes}
        button="Get started"
        link={Routes.APP.USER.SIGN_UP}
      />
      <Testimonials title="Reliable for automated outreach at scale" />
      <BottomCta
        title="Start free 7-day trial now"
        subtitle="Supercharge your outreach with the safest LinkedIn automation tool."
        button="Try safe outreach"
      />
      <PopularQuestionList list={POPULAR_QUESTION_ITEMS} />
      <FeatureRedirectList />
    </div>
  </Layout>
)

export default SafetyPage
